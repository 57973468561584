import { Images } from '~data';
const TestimonialPosts = [
    {
    text:"There are many variations passages of Lorem lpsum available, but the majority have suffered alteration in some form, by injected or randomised.",
    userImage:Images.TestiMOnial1.testiMonialUserImg1,
    userName:"Gary Simon",
    userPosition:"Sholl’s Colonial Cafeteria"
},
{
    text:"There are many variations passages of Lorem lpsum available, but the majority have suffered alteration in some form, by injected or randomised.",
    userImage:Images.TestiMOnial1.testiMonialUserImg2,
    userName:"Karla Lynn",
    userPosition:"Sholl’s Colonial Cafeteria"
},
{
    text:"There are many variations passages of Lorem lpsum available, but the majority have suffered alteration in some form, by injected or randomised.",
    userImage:Images.TestiMOnial1.testiMonialUserImg3,
    userName:"Stella Jennifer",
    userPosition:"Sholl’s Colonial Cafeteria"
},
{
    text:"There are many variations passages of Lorem lpsum available, but the majority have suffered alteration in some form, by injected or randomised.",
    userImage:Images.TestiMOnial1.testiMonialUserImg4,
    userName:"Gary Simon",
    userPosition:"Sholl’s Colonial Cafeteria"
},
{
    text:"There are many variations passages of Lorem lpsum available, but the majority have suffered alteration in some form, by injected or randomised.",
    userImage:Images.TestiMOnial1.testiMonialUserImg5,
    userName:"Karla Lynn",
    userPosition:"Sholl’s Colonial Cafeteria"
},
{
    text:"There are many variations passages of Lorem lpsum available, but the majority have suffered alteration in some form, by injected or randomised.",
    userImage:Images.TestiMOnial1.testiMonialUserImg6,
    userName:"Stella Jennifer",
    userPosition:"Sholl’s Colonial Cafeteria"
}
]

export default TestimonialPosts;